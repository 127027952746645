.card-content {
  height: 33vh;
  min-height: 220px;
}

.card-content,
.card-content .uk-text-lead,
.card-content .uk-button.uk-button-default {
  color: #ffffff;
}

.card-content .uk-background-cover {
  height: 100%;
}

@media only screen and (min-width: 432px) {
  .card-content {
    height: 50vh;
  } 
}